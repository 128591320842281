<template>
  <ResetPassword :domain="'admin'" />
</template>

<script>
import ResetPassword from '@/components/organisms/common/AppCommonResetPassword'

export default {
  name: 'TheSendResetPassword',
  components: {
    ResetPassword
  },
}
</script>
