<template>
  <div class="app-admin-single-card">
    <b-card
      tag="article"
      style="min-height: 460px; width: 350px; border-radius: 16px;"
      class="mb-2"
      body-class="login-card-body"
    >
      <img
        v-if="showLogo"
        :src="pngLogo"
        class="logo"
        width="200"
      />

      <div class="header">
        <div class="title">
          <slot name="title" />
        </div>
        <div class="description">
          <slot name="description" />
        </div>
      </div>

      <div>
        <slot name="contents" />
      </div>

      <slot name="button" />
    </b-card>

    <div class="forgot-password-wrapper">
      <slot name="append" />
    </div>

    <LoadingMask />
  </div>
</template>

<script>
import LoadingMask from '@/components/molecules/common/AppFullscreenLoadingMask'

export default {
  name: 'AppAdminSingleCard',
  components: {
    LoadingMask
  },
  data() {
    return {
      pngLogo: require('@/assets/image/logo/logo.png')
    }
  },
  props: {
    showLogo: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    showLoadingMask() {
      return this.$store.getters.showLoadingMask
    }

  }
}
</script>

<style lang="scss" scoped>
.app-admin-single-card {
  color: gray;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .login-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 16px;
    padding: 32px;
    .logo {
      margin: 0 auto;
    }
    .title {
      font-size: 18px;
      margin-bottom: 0;
    }
    .description {
      text-align: left;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  .forgot-password-wrapper {
    margin: 16px 0;
  }
}
</style>