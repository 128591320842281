<template>
  <AdminSingleCard>
    <template v-slot:title>
      パスワード再設定
    </template>

    <template v-slot:description>
      <p class="reset-password-description">
        登録しているメールアドレスを入力してパスワードの再発行をしてください。
      </p>
    </template>

    <template v-slot:contents>
      <p class="login-email-title">メールアドレス</p>
      <input class="email" type="email" v-model="email" />
    </template>

    <template v-slot:button>
      <div class="button-wrapper">
        <WideButton
          :label="'送信'"
          :disabled="!email"
          @click="$_resetPassword"
        />
        <WideButton
          :label="'ログインに戻る'"
          :backgroundColor="'#707070'"
          @click="() =>  $router.push(`/${domain}/login`)"
        />
      </div>
    </template>
  </AdminSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import AdminSingleCard from '@/templates/admin/AppAdminSingleCard'
import { showInfoPopupAlert, showErrorPopupAlert} from '@/helper/common'
import { auth, sendPasswordResetEmail } from '@/firebase/config'

export default {
  name: 'TheSendResetPassword',
  components: {
    AdminSingleCard,
    WideButton
  },
  props: {
    domain: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      email: '',
      message: '',
      messageType: '',
      callback: null
    }
  },
  watch: {
    message: function () {
      if (this.messageType === 'info') {
        showInfoPopupAlert(
          this.message,
          true,
          {},
          this.callback
        )
      } else if (this.messageType === 'error') {
        showErrorPopupAlert(this.message)
      }
    }
  },
  methods: {
    $_resetPassword: function () {
      if (!this.email) {
        this.messageType = "error"
        this.message = "メールアドレスを入力して下さい"
        return
      }

      this.$store.dispatch('loadingMask/showLoadingMask')

      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.messageType = "info"
          this.message = `${this.email} へパスワード再設定用のリンクを送信しました。`
          if (this.domain === 'user') {
            this.callback = () => this.$router.push('/user/login')
          } else {
            this.callback = () => this.$router.push('/admin/login')
          }
        })
        .catch((error) => {
          this.messageType = "error"
          const errorMessage = error.message;
          if (errorMessage.includes("invalid-email")){
            this.message = "メールアドレスの形式が不正です。"
          } else if (errorMessage.includes("user-not-found")){
            this.message = "入力されたメールアドレスに一致するアカウントはみつかりませんでした。"
          } else {
            this.message = errorMessage
          }
        }).finally(() => {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        })
    },
  },
}
</script>

<style lang="scss">
.reset-password-description {
  margin: 32px 0 16px 0;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.login-email-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.email {
  margin: 0;
}
.button-wrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>